import styled from 'styled-components';

export const Container = styled.div``;

export const CallToWhatsApp = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 30px;
  img {
    width: 100%;
    max-width: 400px;
  }
`;
