import React, { useCallback, useState } from 'react';
import { FaInfoCircle, FaQuestionCircle } from 'react-icons/fa';
import { FaTriangleExclamation } from 'react-icons/fa6';
import { IOrder, IOrderDeliveryOption } from '../interface/Order';
import { formatCurrency } from '~/utils/formatting';
import {
  normalizeCurrency,
  productUrl,
  textCaseFormat,
} from '~/utils/normalize';
import { ModalWarrantyExtended } from './ModalWarrantyExtended';
import ModalPickupStore from './ModalPickupStore';
import { ModalMarketplaceDetail } from './ModalMarketplaceDetail';
import treatTimeDelivery from '~/utils/treatTimeDelivery';
import TagManager from 'react-gtm-module';

interface IProps {
  order: IOrder;
  getSubmitedAddService: (service_id: number) => void; // eslint-disable-line
  getSubmitedRemoveService: (product_sku: string) => void; // eslint-disable-line
  getSubmitedDelivery: (option_id: number, cpf_withdraw?: string) => void;
}

const Products: React.FC<IProps> = ({
  order,
  getSubmitedAddService,
  getSubmitedRemoveService,
  getSubmitedDelivery,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectWithdraw, setSelectWithdraw] = useState({
    options: [] as IOrderDeliveryOption[],
    open: false,
  });
  const [detailMarketplace, setDetailMarkeplace] = useState({
    data: {} as any,
    open: false,
  });

  function handleSubmitAddService(service_id: number) {
    return getSubmitedAddService(service_id);
  }

  function handleSubmitRemoveService(product_sku: string) {
    return getSubmitedRemoveService(product_sku);
  }

  const addShippingInfo = useCallback(
    (deliveryMethod: string, chosenStore?: string | undefined) => {
      TagManager.dataLayer({
        dataLayerName: 'PageDataLayer',
        dataLayer: {
          event: 'add_shipping_info',
          ecommerce: {
            currency: 'BRL',
            value: parseFloat((order.total / 100).toFixed(2)),
            shipping_tier: deliveryMethod,
            loja_retirada: chosenStore || '',
            items: order.orderProducts?.map(orderProducts => ({
              item_id: orderProducts?.product?.sku,
              item_name: orderProducts?.product?.name,
              item_brand: orderProducts?.product?.brand?.name,
              item_category: orderProducts?.product?.categories[0]?.name || '',
              item_category2: orderProducts?.product?.categories[1]?.name || '',
              item_category3: orderProducts?.product?.categories[2]?.name || '',
              item_category4: orderProducts?.product?.categories[3]?.name || '',
              item_category5: orderProducts?.product?.categories[4]?.name || '',
              price: parseFloat(
                (orderProducts?.product?.price_end / 100).toFixed(2),
              ),
              quantity: orderProducts?.qtd,
              is_in_stock: 'in_stock',
              product_image: orderProducts?.product?.photos[0]?.path,
              product_url: productUrl(
                orderProducts?.product?.sku,
                orderProducts?.product?.name,
              ),
            })),
          },
        },
      });
    },
    [order.total, order.orderProducts],
  );

  return (
    <div className="products">
      {detailMarketplace.open && (
        <ModalMarketplaceDetail
          marketplace={detailMarketplace.data}
          onHide={() => {
            setDetailMarkeplace({
              data: {},
              open: false,
            });
          }}
        />
      )}

      {selectWithdraw.open && (
        <ModalPickupStore
          options={selectWithdraw.options}
          onHide={() => {
            setSelectWithdraw({
              options: [],
              open: false,
            });
          }}
          handleSubmit={data => {
            getSubmitedDelivery(data.id, data.cpf_withdraw);
          }}
          addShippingInfo={addShippingInfo}
        />
      )}
      <ModalWarrantyExtended
        open={openModal}
        setOpen={() => setOpenModal(false)}
      />
      {order.orderDeliveries?.map(item => {
        const frete = item.deliveryOptions.filter(
          d => d.delivery_type === 'frete',
        );
        const cliqueeretire = item.deliveryOptions.filter(
          d => d.delivery_type === 'cliqueeretire',
        );
        const selectedDelivery = item.deliveryOptions.find(
          d => d.is_selected === 1,
        );
        return (
          <div className="products-by-integrator" key={item.integrator_id}>
            <div>
              <div>
                {item.orderProducts.map(product => (
                  <div className="grid-product">
                    <img
                      src={product.product?.photos[0]?.path}
                      alt={product.product.name}
                    />
                    <div>
                      <h6 className="m-0">
                        {product.qtd}x {product.product.name}
                      </h6>

                      {product.productVariation ? (
                        <p className="m-0">
                          {textCaseFormat(
                            product.productVariation?.variation?.variationType
                              ?.description,
                          )}
                          :{' '}
                          {textCaseFormat(
                            product.productVariation?.variation?.description,
                          )}
                        </p>
                      ) : null}
                      <p className="m-0">
                        <h5 className="text-orange mb-0">
                          <strong>
                            {formatCurrency(String(product.price))}
                          </strong>
                        </h5>
                        {product.product?.price_in_full ||
                        product.product?.price_discount ? (
                          <small className="d-block text-muted">
                            ou{' '}
                            {formatCurrency(
                              String(
                                product.product?.price_in_full ||
                                  product.product?.price_discount,
                              ),
                            )}{' '}
                            à vista no Pix
                          </small>
                        ) : null}
                      </p>
                      <small className="text-muted">
                        Vendido e entrege por:{' '}
                        <button
                          type="button"
                          className="text-primary"
                          onClick={() =>
                            setDetailMarkeplace({
                              open: true,
                              data: product.product.marketplace,
                            })
                          }
                        >
                          {product.product.marketplace.name} <FaInfoCircle />
                        </button>
                      </small>
                      {product.product.services &&
                        product.product.services.length > 0 &&
                        product.product.marketplace_id === 1 && (
                          <div className="d-flex flex-column form-group mt-2">
                            <div className="garantia-estendida form-check">
                              <input
                                name={`garantia_${product.sku}`}
                                type="radio"
                                className="form-check-input"
                                checked={
                                  order.orderServices &&
                                  order.orderServices.filter(
                                    s => s.product_sku === product.sku,
                                  ).length === 0
                                }
                                onClick={() =>
                                  handleSubmitRemoveService(product.sku)
                                }
                              />
                              <p className="form-check-label mb-0">
                                Sem Garantia Estendida
                              </p>
                            </div>
                            {product.product.services.map(ele => {
                              return (
                                <div
                                  key={ele.id}
                                  className="garantia-estendida security form-check"
                                >
                                  <img src="/assets/img/security3.png" alt="" />
                                  <input
                                    name={`garantia_${product.sku}_${product.id}`}
                                    type="radio"
                                    className="form-check-input"
                                    checked={
                                      order.orderServices &&
                                      order.orderServices.filter(
                                        s =>
                                          s.service_sku === ele.service_sku &&
                                          s.product_sku === product.sku,
                                      ).length > 0
                                    }
                                    onClick={() =>
                                      handleSubmitAddService(ele.id)
                                    }
                                  />
                                  <p className="form-check-label mb-0">
                                    {ele.name} - R${' '}
                                    {normalizeCurrency(String(ele.price))}{' '}
                                    <button
                                      type="button"
                                      className="ml-2"
                                      onClick={() => setOpenModal(true)}
                                    >
                                      <FaQuestionCircle className="text-primary" />
                                    </button>
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        )}
                    </div>
                  </div>
                ))}
              </div>
              <div>
                <h6 className="mb-1">Opções de Entrega</h6>
                {order.orderDeliveryAddress?.address?.zip_code ? (
                  <div>
                    {frete.length === 0 && cliqueeretire.length === 0 && (
                      <>
                        <hr className="mb-1 mt-2" />
                        <small className="box-info">
                          <FaTriangleExclamation className="box-info__icon" />
                          <span className="box-info__text">
                            Entrega não disponível para endereço selecionado
                          </span>
                        </small>
                      </>
                    )}
                    {frete.length > 0 && (
                      <>
                        <hr className="mb-1 mt-2" />
                        <small>Entrega em Casa</small>
                      </>
                    )}
                    {frete.map(f => (
                      <div className="d-flex align-items-center" key={f.id}>
                        <input
                          name={`delivery-${item.integrator_id}-${item.marketplace_id}`}
                          className="mb-0 mr-1"
                          type="radio"
                          checked={f.is_selected === 1}
                          onChange={() => {
                            getSubmitedDelivery(f.id);
                            addShippingInfo(f.delivery_type, f.store?.name);
                          }}
                        />
                        <p className="form-check-label mb-0">
                          {f.price === 0 ? (
                            <strong className="text-success">GRÁTIS</strong>
                          ) : (
                            formatCurrency(String(f.price))
                          )}{' '}
                          Entrega {f.title} -{' '}
                          {treatTimeDelivery(Number(f.days))}
                        </p>
                      </div>
                    ))}
                    {cliqueeretire.length > 0 && (
                      <>
                        <hr className="mb-1 mt-2" />
                        <small>
                          Retirar na Loja{' '}
                          <span className="text-success">GRÁTIS</span>
                        </small>
                        {selectedDelivery &&
                        selectedDelivery.delivery_type === 'cliqueeretire' ? (
                          <div>
                            <div className="d-flex align-items-center">
                              <input
                                className="mb-0 mr-1"
                                type="radio"
                                checked
                              />
                              <p className="form-check-label mb-0">
                                {selectedDelivery.store?.name} -{' '}
                                {selectedDelivery.days >= 1
                                  ? treatTimeDelivery(selectedDelivery.days)
                                  : 'A partir de 3 horas'}
                              </p>
                            </div>
                            <button
                              className="text-primary text-underline p-0 mt-0"
                              type="button"
                              onClick={() =>
                                setSelectWithdraw({
                                  open: true,
                                  options: cliqueeretire,
                                })
                              }
                            >
                              <small>Alterar Loja</small>
                            </button>
                          </div>
                        ) : (
                          <div>
                            <button
                              className="btn btn-imperio btn-sm pt-1 pb-1 mt-0"
                              type="button"
                              onClick={() =>
                                setSelectWithdraw({
                                  open: true,
                                  options: cliqueeretire,
                                })
                              }
                            >
                              <small>Escolher Loja</small>
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <p>
                    Selecione seu endereço acima para visualizar as opções de
                    entrega.
                  </p>
                )}
              </div>
            </div>
          </div>
        );
      })}
      {/** 
       <div className="product">
        <table>
          <thead>
            <tr>
              <th>Nome do produto</th>
              <th>Preço</th>
              <th>Qtd.</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {!order.loading ? (
              order.orderProducts?.map(e => {
                return (
                  <tr key={e.sku}>
                    <td>
                      <p>{e.product.name}</p>
                      <p>
                        Vendido por:{' '}
                        <span className="ml-1">
                          {e.product.marketplace.name}
                        </span>
                      </p>
                      {e.product.services && (
                        <div className="d-flex flex-column form-group mt-1">
                          <div className="garantia-estendida form-check">
                            <input
                              name={`garantia_${e.sku}`}
                              type="radio"
                              className="form-check-input"
                              checked={
                                order.orderServices &&
                                order.orderServices.filter(
                                  s => s.product_sku === e.sku,
                                ).length === 0
                              }
                              onClick={() => handleSubmitRemoveService(e.sku)}
                            />
                            <p className="form-check-label">
                              Sem Garantia Estendida
                            </p>
                          </div>
                          {e.product.services.map(ele => {
                            return (
                              <div
                                key={ele.id}
                                className="garantia-estendida security form-check"
                              >
                                <img src="/assets/img/security3.png" alt="" />
                                <input
                                  name={`garantia_${e.sku}`}
                                  type="radio"
                                  className="form-check-input"
                                  checked={
                                    order.orderServices &&
                                    order.orderServices.filter(
                                      s => s.service_sku === ele.service_sku,
                                    ).length > 0
                                  }
                                  onClick={() => handleSubmitAddService(ele.id)}
                                />
                                <p className="form-check-label">
                                  {ele.name} - R${' '}
                                  {normalizeCurrency(String(ele.price))}{' '}
                                  <button
                                    type="button"
                                    className="ml-2"
                                    onClick={() => setOpenModal(true)}
                                  >
                                    <FaQuestionCircle className="text-primary" />
                                  </button>
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </td>
                    <td>
                      <p>{formatCurrency(String(e.price))}</p>
                    </td>
                    <td>
                      <p>{e.qtd}</p>
                    </td>
                    <td>
                      <p>{formatCurrency(String(e.price * e.qtd))}</p>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td>
                  <span className="textLoading" />
                </td>
                <td>
                  <span className="textLoading" />
                </td>
                <td>
                  <span className="textLoading" />
                </td>
                <td>
                  <span className="textLoading" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      */}
    </div>
  );
};

export default Products;
