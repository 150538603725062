import api from '~/services/api';
import { normalizeCurrency, textCaseFormat } from '~/utils/normalize';
import QRCode from 'qrcode.react';
import React, { useEffect, useMemo, useState } from 'react';
import { toastr } from 'react-redux-toastr';

import { IHeaders } from './interface/Headers';
import { IOrder } from './interface/Order';
import { IProps } from './interface/Props';
import { formatCurrency } from '~/utils/formatting';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';

import { MdQueryBuilder } from 'react-icons/md';
import { FaCheckCircle } from 'react-icons/fa';
import { TbPlugX } from 'react-icons/tb';
import { CallToWhatsApp } from './styles';

const PIX_TIME_TO_EXPIRE = 20;
const POOL_DELAY = 10000;

const TimeLeft = ({
  timeLeft,
  onTimeExpired,
}: {
  timeLeft: number;
  // eslint-disable-next-line react/require-default-props
  onTimeExpired?: () => void;
}) => {
  const [time, setTime] = useState(timeLeft);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(time - 1);
    }, 1000);

    if (time <= 0 && onTimeExpired) {
      onTimeExpired();
    }

    return () => clearInterval(interval);
  }, [time, onTimeExpired]);

  return (
    <div className="time-left">
      <div className="time-left__title">Tempo restante para pagamento: </div>
      <strong
        className="d-block p-2 text-center"
        style={{ borderRadius: '4px', background: '#fff', color: '#006cb2' }}
      >
        {moment(time * 1000).format('mm:ss')}
      </strong>
    </div>
  );
};

const PixQRCodeComponent = ({
  content,
  value,
  createDate,
  onTimeExpired,
}: {
  content: string;
  value: number;
  createDate: string;
  // eslint-disable-next-line react/require-default-props
  onTimeExpired?: () => void;
}) => {
  const expireMoment = moment(createDate).add(PIX_TIME_TO_EXPIRE, 'minutes');
  const timeLeft = moment(expireMoment).diff(moment(), 'seconds');

  useEffect(() => {
    if (timeLeft <= 0 && onTimeExpired) {
      onTimeExpired();
    }
  });

  return (
    <div className="align-items-center d-flex flex-column mt-4">
      <TimeLeft timeLeft={timeLeft} onTimeExpired={onTimeExpired} />
      <br />
      <QRCode value={content} size={200} />

      <strong className="mt-2">
        Valor: R$ {normalizeCurrency(String(value) || '000')}
      </strong>

      <button
        type="button"
        className="mt-2 btn btn-imperio"
        onClick={() => {
          navigator.clipboard.writeText(content);
          toastr.info(' ', 'Código PIX Copiado');
        }}
      >
        COPIAR CÓDIGO PIX
      </button>
    </div>
  );
};
const Checkout: React.FC<IProps> = ({ match }) => {
  const [linkBoleto, setLinkBoleto] = useState<string>('');

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<'finished' | 'pay-pix' | 'error-fetch'>(
    'finished',
  );

  const [pixPayments, setPixPayments] = useState<any[]>([]);
  const [order, setOrder] = useState<IOrder>({
    id: 0,
    employee_id: null,
    seller_id: 0,
    order_status_id: 0,
    code_coupon: '',
    service: 0,
    subtotal: 0,
    discount: 0,
    discount_form_payment: 0,
    fees: 0,
    frete: 0,
    total: 0,
    loading: true,
    mask: true,
  });
  const [orderProducts, setOrderProducts] = useState<IOrder['orderProducts']>(
    [],
  );
  const [orderDeliveries, setOrderDeliveries] = useState<any[]>([]);
  const { id } = match.params;

  const [isImperioPaid, setIsImperioPaid] = useState(false);
  const [isPromoterPaid, setIsPromoterPaid] = useState(false);

  const [timeExpired, setTimeExpired] = useState(false);

  const handleTimeExpired = () => {
    setTimeExpired(true);
  };

  const headersTokens = (): IHeaders => {
    const token = localStorage.getItem('imperio@token');
    const tokenBuyer = localStorage.getItem('imperio@tokenBuyer');

    if (token) {
      return {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
    }
    if (tokenBuyer) {
      return {
        headers: {
          tokenBuyer,
        },
      };
    }
    return {
      headers: {},
    };
  };

  const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

  useEffect(() => {
    async function poolApi() {
      if (
        order?.form_payment === 'pix' &&
        order?.orderPayment &&
        (order?.orderPayment?.length > 1 ||
          (order?.orderPayment[0] as any)?.orderPaymentPix?.is_promoter === 0)
      ) {
        while (!isImperioPaid) {
          const headers: IHeaders = headersTokens();
          const { data: dataOrder } = await api.get(
            `/v2/orders/${id}`,
            headers,
          );

          for (let i = 0; i < dataOrder.orderPayment.length; i += 1) {
            const payment = dataOrder.orderPayment[i];

            if (payment.orderPaymentPix) {
              if (
                !payment.orderPaymentPix.is_promoter &&
                payment.orderPaymentPix.status === 'CONCLUIDA'
              ) {
                setIsImperioPaid(true);
                return;
              }

              if (
                !payment.orderPaymentPix.is_promoter &&
                payment.orderPaymentPix.status === 'EXPIRADA'
              ) {
                return;
              }
            }
          }

          await delay(POOL_DELAY);
        }
      }
    }

    if (order) {
      poolApi();
    }
  }, [order, isImperioPaid, id]);

  useEffect(() => {
    async function poolApi() {
      if (
        order?.form_payment === 'pix' &&
        order?.orderPayment &&
        (order?.orderPayment?.length > 1 ||
          (order?.orderPayment[0] as any)?.orderPaymentPix?.is_promoter === 1)
      ) {
        while (!isPromoterPaid) {
          const headers: IHeaders = headersTokens();
          const { data: dataOrder } = await api.get(
            `/v2/orders/${id}`,
            headers,
          );

          for (let i = 0; i < dataOrder.orderPayment.length; i += 1) {
            const payment = dataOrder.orderPayment[i];

            if (payment.orderPaymentPix) {
              if (
                payment.orderPaymentPix.is_promoter &&
                payment.orderPaymentPix.status === 'CONCLUIDA'
              ) {
                setIsPromoterPaid(true);
                return;
              }
            }
            if (
              payment.orderPaymentPix.is_promoter &&
              payment.orderPaymentPix.status === 'EXPIRADA'
            ) {
              return;
            }
          }

          await delay(POOL_DELAY);
        }
      }
    }

    if (order) {
      poolApi();
    }
  }, [order, isImperioPaid, isPromoterPaid, id]);

  async function getOrder() {
    setLoading(true);
    try {
      setOrder({ ...order, loading: true });

      const headers: IHeaders = headersTokens();

      const { data: dataOrder } = await api.get(`/v2/orders/${id}`, headers);
      setOrder({ ...dataOrder, loading: false });

      let arr: any[] = [];
      let delivery: any[] = [];

      dataOrder.orderDeliveries?.forEach((d: any) => {
        if (d.orderProducts) {
          arr = [...arr, ...d.orderProducts];
        }
        if (d.deliveryOptions) {
          delivery = [
            ...delivery,
            ...d.deliveryOptions.filter((o: any) => o.is_selected === 1),
          ];
        }
      });

      setOrderDeliveries(delivery);

      setOrderProducts(arr);

      if (dataOrder.form_payment === 'boleto') {
        let responseBoleto =
          dataOrder.orderPayment[0]?.orderPaymentBoleto?.response;
        if (responseBoleto && !dataOrder.mask) {
          responseBoleto = JSON.parse(responseBoleto);
          setLinkBoleto(responseBoleto.formats.pdf);
        }
      }

      if (dataOrder.form_payment === 'pix') {
        for (let i = 0; i < dataOrder.orderPayment.length; i += 1) {
          const payment = dataOrder.orderPayment[i];

          if (payment.orderPaymentPix) {
            setPixPayments(prev => [...prev, payment.orderPaymentPix]);
          }
        }
      }
    } catch (e) {
      console.log(e);
      setStep('error-fetch');
    }
    setLoading(false);
  }

  useEffect(() => {
    getOrder();
    localStorage.removeItem('imperio@order_id');
    localStorage.removeItem('imperio@tokenBuyer');
    localStorage.removeItem('imperio@token');
  }, []); // eslint-disable-line

  function handlePixFlow(pix: any): JSX.Element {
    if (!pix.is_promoter && isImperioPaid && isPromoterPaid) {
      return <></>;
    }
    if (pix.is_promoter && isImperioPaid && isPromoterPaid) {
      return (
        <div>
          <p>Todos os pagamentos realizados! Obrigado pela preferência.</p>
          <p>
            Para acompanhar o seu pedido, clique{' '}
            <a href="https://lojasimperio.com.br/u/meus-pedidos">
              Meus pedidos
            </a>
            <br />e consulte o status, através do seu CPF.
          </p>
        </div>
      );
    }
    if (pix.is_promoter && !isImperioPaid && !isPromoterPaid) {
      return (
        <strong className="d-block text-center">
          Aguardando o pagamento da primeira transação...
        </strong>
      );
    }
    if (pix.is_promoter && isImperioPaid && !isPromoterPaid) {
      return (
        <div className="align-items-center d-flex flex-column">
          <PixQRCodeComponent
            content={pix.qr_code_text}
            value={pix.value}
            createDate={pix.create_date}
            onTimeExpired={handleTimeExpired}
          />
        </div>
      );
    }
    if (!pix.is_promoter && !isImperioPaid) {
      return (
        <div className="align-items-center d-flex flex-column">
          <PixQRCodeComponent
            content={pix.qr_code_text}
            value={pix.value}
            createDate={pix.create_date}
            onTimeExpired={handleTimeExpired}
          />
        </div>
      );
    }
    if (!pix.is_promoter && isImperioPaid) {
      return <></>;
      // return (
      //   <div className="align-items-center d-flex flex-column">
      //     <QRCode
      //       value={pix.qr_code_text}
      //       style={{ filter: `blur(10px)` }}
      //       size={200}
      //     />
      //
      //     <strong className="mt-4">Primeiro pagamento realizado!</strong>
      //   </div>
      // );
    }
    return <></>;
  }

  function handleList(type: 'imperio' | 'promotora'): JSX.Element {
    const deliveryImperio = orderDeliveries.find(
      o => Number(o.integration_id) === 1,
    );
    const deliveriesPromotora = orderDeliveries.filter(
      o => Number(o.integration_id) > 1,
    );

    const fretePromotora = deliveriesPromotora.reduce((acc, item) => {
      acc += item.price;
      return acc;
    }, 0);

    return (
      <div>
        <strong>
          {type === 'imperio'
            ? 'Produtos sendo pagos'
            : 'Produtos/Serviços sendo pagos'}
          :
        </strong>
        <div className="products-by-integrator">
          <div style={{ gridTemplateColumns: '1fr' }}>
            {orderProducts
              ?.filter(p =>
                type === 'imperio'
                  ? p.product.marketplace_id === 1
                  : p.product.marketplace_id > 1,
              )
              .map(o => (
                <div
                  className="grid-product"
                  style={{ gridTemplateColumns: '1fr 5fr' }}
                  key={o.id}
                >
                  <img src={o.product?.photos[0]?.path} alt={o.product.name} />
                  <div>
                    <h6 className="m-0">
                      {o.qtd}x {o.product.name}
                    </h6>
                    {o.productVariation ? (
                      <p className="m-0">
                        {textCaseFormat(
                          o.productVariation?.variation?.variationType
                            ?.description,
                        )}
                        :{' '}
                        {textCaseFormat(
                          o.productVariation?.variation?.description,
                        )}
                      </p>
                    ) : null}
                    <p className="m-0">
                      <strong className="text-orange">
                        {formatCurrency(
                          String(o.price - (o.discount_form_payment || 0)),
                        )}
                      </strong>
                    </p>
                    <small className="text-muted">
                      Vendido e entrege por: {o.product.marketplace.name}
                    </small>
                  </div>
                </div>
              ))}
            {type === 'imperio' && deliveryImperio && (
              <div
                className="grid-product"
                style={{ gridTemplateColumns: '1fr 5fr' }}
              >
                <img
                  src="https://lojasimperio.com.br/images/introduct1.png"
                  alt="Frete"
                />
                <div>
                  <h6 className="m-0">
                    {deliveryImperio.delivery_type === 'frete'
                      ? 'Frete'
                      : 'Retirar na Loja'}
                  </h6>
                  <p className="m-0">
                    <strong className="text-orange">
                      {deliveryImperio.price > 0
                        ? formatCurrency(String(deliveryImperio.price))
                        : 'GRÁTIS'}
                    </strong>
                  </p>
                  {deliveryImperio?.store && (
                    <small className="d-block">
                      <i>{deliveryImperio?.store?.name}</i>
                    </small>
                  )}
                </div>
              </div>
            )}
            {type === 'promotora' &&
              order.orderServices?.map(service => {
                const orderProduct = orderProducts?.find(
                  o => o.sku === service.product_sku,
                );
                return (
                  <div
                    className="grid-product"
                    key={service.service_id}
                    style={{ gridTemplateColumns: '1fr 5fr' }}
                  >
                    <div className="d-flex justify-content-center">
                      <img
                        src="/assets/img/security3.png"
                        alt="Garantia Estendida"
                        style={{ maxWidth: '60px' }}
                      />
                    </div>
                    <div>
                      <h6 className="m-0">
                        <strong>Garantia Estendida: </strong>{' '}
                        {service.service?.name}
                      </h6>
                      <small className="d-block text-muted">
                        <i>{orderProduct?.product?.name}</i>
                      </small>
                      <p className="m-0">
                        <strong className="text-orange">
                          {formatCurrency(String(service?.price))}
                        </strong>
                      </p>
                    </div>
                  </div>
                );
              })}

            {type === 'promotora' && deliveriesPromotora.length > 0 && (
              <div
                className="grid-product"
                style={{ gridTemplateColumns: '1fr 5fr' }}
              >
                <img
                  src="https://lojasimperio.com.br/images/introduct1.png"
                  alt="Frete"
                />
                <div>
                  <h6 className="m-0">Frete</h6>
                  <p className="m-0">
                    <strong className="text-orange">
                      {fretePromotora > 0
                        ? formatCurrency(String(fretePromotora))
                        : 'GRÁTIS'}
                    </strong>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <CallToWhatsApp>
          <a
            href="https://whatsapp.com/channel/0029VaPClOcDzgTBBZqmSO3t"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://lojasimperio-s3.s3.amazonaws.com/lojasimp/site/2024/BOT%C3%83O-WHATSAPP.png"
              alt="Entre em nosso canal do whatsapp e aproveite as ofertas exclusivas."
            />
          </a>
        </CallToWhatsApp>
      </div>
    );
  }

  const isFullPaid = useMemo(() => {
    if (order.form_payment !== 'pix') {
      return true;
    }

    if (pixPayments.length === 2) {
      return isImperioPaid && isPromoterPaid;
    }

    return pixPayments[0]?.is_promoter ? isPromoterPaid : isImperioPaid;
  }, [isImperioPaid, isPromoterPaid, pixPayments]);

  useEffect(() => {
    if (isFullPaid) {
      setStep('finished');
    } else {
      setStep('pay-pix');
    }
  }, [isFullPaid]);

  if (loading) {
    return (
      <div className="container">
        <Spinner animation="border" /> Carregando
      </div>
    );
  }

  if (step === 'pay-pix') {
    return (
      <div className="container page__confirm">
        <div className="d-flex justify-content-center">
          <MdQueryBuilder
            size="4rem"
            color="#c9c9c9"
            className="text-center mb-2"
          />
        </div>
        <h4 className="text-center">
          Para concluir o pedido, realize o pagamento
        </h4>
        <p className="text-center">
          Ao realizar o pagamento você será informado via email
        </p>
        <hr />
        {pixPayments.length === 2 && (
          <div>
            <div className="d-flex flex-wrap">
              {isImperioPaid ? (
                <span className="text-success ml-2 mr-2">
                  <FaCheckCircle /> Primeiro pagamento
                </span>
              ) : (
                <span className="text-muted ml-2 mr-2">
                  <MdQueryBuilder /> Primeiro pagamento
                </span>
              )}
              {isPromoterPaid ? (
                <span className="text-success ml-2 mr-2">
                  <FaCheckCircle /> Segundo pagamento
                </span>
              ) : (
                <span className="text-muted ml-2 mr-2">
                  <MdQueryBuilder /> Segundo pagamento
                </span>
              )}
            </div>
            <hr />
          </div>
        )}
        {pixPayments.length === 1 && !timeExpired && (
          <div className="pix_payment">
            <div className="row">
              <div className="col-md-5">
                {pixPayments.map(pix => {
                  return (
                    <PixQRCodeComponent
                      content={pix.qr_code_text}
                      value={pix.value}
                      createDate={pix.create_date}
                      onTimeExpired={handleTimeExpired}
                      key={pix.id}
                    />
                  );
                })}
                <p className="mt-4 text-center">
                  Escaneie o QR Code ou copie o Código PIX.
                  <br />
                  Este é um <strong>PIX copia e cola</strong>. No seu App ou
                  Internet Banking procure por esta opção para colar o texto
                  copiado.
                </p>
              </div>
              <div className="col-md-7">
                {pixPayments[0]?.is_promoter === 1
                  ? handleList('promotora')
                  : handleList('imperio')}
              </div>
            </div>
          </div>
        )}

        {pixPayments.length === 2 && !timeExpired && (
          <div className="pix_payment">
            <div className="row">
              <div className="col-md-5">
                <div className="d-flex align-items-center justify-content-center flex-column">
                  {pixPayments.map(pix => (
                    <div
                      key={pix.id}
                      className="d-flex flex-column justify-content-center align-items-center d-flex"
                    >
                      <div className="">{handlePixFlow(pix)}</div>
                    </div>
                  ))}
                  {(!isImperioPaid || !isPromoterPaid) && !timeExpired && (
                    <p className="mt-4 text-center">
                      Escaneie o QR Code ou copie o Código PIX.
                      <br />
                      Este é um <strong>PIX copia e cola</strong>. No seu App ou
                      Internet Banking procure por esta opção para colar o texto
                      copiado.
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-7">
                {!isImperioPaid && handleList('imperio')}
                {isImperioPaid && !isPromoterPaid && handleList('promotora')}
              </div>
              <div className="col-md-12 mt-3">
                <div className="alert alert-warning">
                  {(!isImperioPaid || !isPromoterPaid) && (
                    <div className="text-center">
                      <strong>
                        Atenção! Para este pedido é necessário realizar o
                        pagamento em duas transações
                      </strong>
                      <br />
                      <strong>Estes QR codes expiram em 20 minutos</strong>
                      <p className="mb-0">
                        Após realizado o primeiro pagamento o segundo QR Code
                        será liberado.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {timeExpired && (
          <div className="text-center">
            <strong className="text-danger">
              Tempo de pagamento expirado.
            </strong>
            <p>
              Se o pagamento foi realizado, em breve seu pedido será aprovado.
            </p>
            <br />
          </div>
        )}
      </div>
    );
  }

  if (step === 'error-fetch') {
    return (
      <div className="container page__confirm">
        <div className="d-flex justify-content-center">
          <TbPlugX size="4rem" color="#c9c9c9" className="text-center mb-2" />
        </div>
        <h4 className="text-center">Ops... Ocorreu erro ao carregar</h4>
        <p className="text-center">
          Atualize a tela para recarregar as informações
        </p>
      </div>
    );
  }

  return (
    <div className="container page__confirm">
      <div className="row">
        <div className="col-12">
          <div className="header">
            <i className="fas fa-check-circle" />
            <h3>O seu pedido foi finalizado com sucesso.</h3>
            <p>
              Agradecemos a sua preferência! Abaixo, você pode ver o número
              <br />
              do seu pedido e resumo da compra. Todos os dados serão enviados
              <br />
              no e-mail cadastrado. Aguarde a confirmação do pagamento.
            </p>
            <strong>Número do pedido:</strong>
            <span>{String(order.id).padStart(9, '0')}</span>
          </div>
          <div className="resume">
            <small>Resumo do pedido</small>
            <div className="resume__products">
              {order.orderProducts &&
                order.orderProducts.map(e => {
                  return (
                    <div className="resume__product" key={e.sku}>
                      <figure>
                        <img
                          src={e.product.photos[0].path}
                          alt={e.product.name}
                        />
                      </figure>
                      <div>
                        <p>{e.product.name}</p>
                        <p>R$ {normalizeCurrency(String(e.price))}</p>
                        <span>
                          SKU: {e.sku} | Quantidade: {e.qtd} unidade
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div>

            <div className="resume__payment">
              <div>
                <p>Tipo de pagamento:</p>
                <strong>
                  {order.orderPayment && //eslint-disable-line
                  order.orderPayment[0].form_payment_id === 1 //eslint-disable-line
                    ? 'Boleto Bancário' //eslint-disable-line
                    : order.orderPayment &&
                      order.orderPayment[0].form_payment_id === 5 //eslint-disable-line
                    ? 'PIX' //eslint-disable-line
                    : 'Cartão de crédito'}{' '}
                </strong>
              </div>
              <div>
                <p>Subtotal:</p>
                <strong>
                  R${' '}
                  {normalizeCurrency(
                    String(order.subtotal - (order.fees || 0)),
                  )}
                </strong>
              </div>
              {order.service > 0 && (
                <div>
                  <p>Serviços:</p>
                  <strong>R$ {normalizeCurrency(String(order.service))}</strong>
                </div>
              )}
              {(order.discount > 0 || order.discount_form_payment > 0) && (
                <div>
                  <p>Desconto:</p>
                  <strong>
                    - R${' '}
                    {normalizeCurrency(
                      String(order.discount + order.discount_form_payment),
                    )}
                  </strong>
                </div>
              )}
              {order.fees > 0 ? (
                <div>
                  <p>Juros:</p>
                  <strong>R$ {normalizeCurrency(String(order.fees))}</strong>
                </div>
              ) : null}
              <div>
                <p>Valor do frete:</p>
                <strong>R$ {normalizeCurrency(String(order.frete))}</strong>
              </div>
              {order.orderPayment &&
                order.orderPayment[0].form_payment_id === 2 && (
                  <div>
                    <p>Parcelas:</p>
                    <strong>
                      Em{' '}
                      {order.orderPayment && order.orderPayment[0].installments}{' '}
                      vez(es) de R${' '}
                      {order.orderPayment && order.orderPayment[0].total
                        ? normalizeCurrency(
                            String(
                              Math.round(
                                order.orderPayment[0].total /
                                  order.orderPayment[0].installments,
                              ),
                            ),
                          )
                        : ''}
                    </strong>
                  </div>
                )}
              <div>
                <p>Total:</p>
                <strong>R$ {normalizeCurrency(String(order.total))}</strong>
              </div>
            </div>
            <div className="w-100">
              {linkBoleto !== '' && (
                <a
                  href={linkBoleto}
                  rel="noreferrer"
                  target="_blank"
                  className="btn btn-orange mr-3"
                >
                  Imprimir boleto
                </a>
              )}

              {pixPayments.length > 0 && isFullPaid && (
                <div>
                  <strong>Pagamento realizado com sucesso!</strong>
                  <p>
                    Enviaremos atualizações de seus pedidos via e-mail ou se
                    preferir, acompanhe seu pedido{' '}
                    <a href="https://lojasimperio.com.br/u/meus-pedidos">
                      Clicando aqui
                    </a>
                    .
                  </p>
                  <br />
                </div>
              )}
              <a
                href="https://lojasimperio.com.br/"
                className="mt-4 btn btn-imperio"
              >
                Voltar para página inicial
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
